





































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Getter, namespace } from 'vuex-class';
import { CommunityUserAgendaEntryFilter } from '@/graphql/_Filters/CommunityUserAgendaEntryFilter';
import CommunityUserAgendaEntry from '@/models/graphql/CommunityUserAgendaEntry';
import GUUID from '@/utils/GUUID';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import CommunityFeature from '@/models/graphql/CommunityFeature';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';

const communityUserAgendaEntryStore = namespace('CommunityUserAgendaEntryStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonAgenda extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: string | null;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserAgendaEntryStore.Action
  private addToAgenda!: (
      payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Action
  private removeFromAgenda!: (
      payload: CommunityUserAgendaEntryFilter,
  ) => Promise<CommunityUserAgendaEntry | undefined>;

  @communityUserAgendaEntryStore.Getter
  private isLoading!: boolean;

  private isInAgenda: string | null = this.actionResult;

  private get text(): string {
    return `${this.isInAgenda ? this.$t('actions.remove-from-agenda') : this.$t('actions.add-to-agenda')}`;
  }

  private get variant(): string {
    return this.isInAgenda ? 'alt-2' : 'secondary';
  }

  private get icon(): string {
    return this.isInAgenda ? 'fa-regular fa-calendar-check' : 'fa-regular fa-calendar-plus';
  }

  private get isDisplayed(): boolean {
    return (this.authUser
        && this.featureByKey(FeatureKeys.COMMUNITY_AGENDA_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_AGENDA_FEATURE).enabled
        && this.entityType === this.EntityTypeEnum.SESSION
        && this.actionType === this.ActionButtonType.AGENDA);
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  @Watch('actionResult')
  private syncActionResultData(): void {
    this.isInAgenda = this.actionResult;
  }

  private toggleAgenda(): void {
    if (this.isInAgenda) {
      const backupValue = this.isInAgenda;
      this.isInAgenda = null;
      this.removeFromAgenda({ uid: backupValue })
        .catch(() => {
          this.isInAgenda = backupValue;
        });
      this.logStats(StatLoggerCategories.REMOVE, StatLoggerActions.REMOVE_FROM_AGENDA, 'removeAppointment');
    } else {
      this.isInAgenda = GUUID.uuidv4();
      this.addToAgenda({
        userId: this.authUser.uid,
        linkedUserId: this.entityUid,
        entityType: this.entityType,
      })
        .then((response) => {
          if (response) {
            this.logStats(StatLoggerCategories.ADD, StatLoggerActions.ADD_TO_AGENDA, 'addAppointment');
            this.isInAgenda = response.uid;
          } else {
            this.isInAgenda = null;
          }
        })
        .catch(() => {
          this.isInAgenda = null;
        });
    }
  }
}
